<template>
  <div class="v-prod-button">
    <button 
      class="btn" 
      :disabled="!okStatus"
      @click="clickBtn()">
      <div class="flex-wrapper">
        <div><i class="material-icons">{{ current.icon }}</i></div>
        <span>{{ current.txt }}</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'v-prod-button',
  data () {
    return {
      toCart: { txt: 'В кошик', icon: 'add_shopping_cart' },
      update: { txt: 'Оновити', icon: 'shopping_cart_checkout' },
      outOfStock: { txt: 'Відсутній', icon: 'remove_shopping_cart' },
    }
  },

  props: {
    isInStock: {
      type: Boolean,
      default: true
    },
    isInCart: {
      type: Boolean,
      default: false
    },
    isWarn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickBtn () {
      this.$emit('clickBtn')
    }
  },
  computed: {
    okStatus () {
      return (this.isInStock && !this.isWarn) ? true : false
    },
    current () {
      if (this.isInStock && !this.isInCart) return this.toCart
      if (this.isInStock && this.isInCart) return this.update
      if (!this.isInStock) return this.outOfStock
    }
  }
} 

</script>