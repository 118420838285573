<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([ 
      'PRODUCTS' 
    ])
  },

  methods: {
    validate () {
      if (this.prodsQt < 1) {
        this.isWarn = true
      } else this.isWarn = false
    },
    makeID () {
      return Number((Date.now()/100).toFixed().slice(4))
    },
  }
}
</script>
