<template>
  <div class="v-size-selector">
    <div class="label-txt">Розмір:</div>
      <div class="input-block">
        <select 
        :value="currentSize" 
        v-model="currentSize"
        @change="changeSize()">
          <option v-for="item in available" :key="item.id" 
            :value="Object.keys(item).join()">
            {{ Object.keys(item).join() | toUpper}}
          </option>
        </select>
      </div>
  </div>
</template>

<script>
import toUpper from '../../filters/toUpperCase'
export default {
  name: 'v-size-selector',
  data () {
    return {
      currentSize: 'l'
    }
  },
  props: {
    available: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    changeSize () {
      this.$emit('changeSize', this.currentSize)
    }
  },
  filters: {
    toUpper
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
.v-size-selector {
  display: flex;
  justify-content: flex-end;
  gap: .6rem;
  align-items: center;
  margin: -7rem 0 4rem 0;
  & .label-txt {
    flex: 0 1 4rem;
    line-height: 2rem;
    text-align: end;
    background-color: $semi-trans-bgrnd;
  }
  .input-block {
    & select {
      border: unset;
      width: 3.5rem;
      padding: 0.75rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      /* Arrow */
      appearance: none;
      background-image: url('../../assets/images/decr_arrow.svg');
      background-repeat: no-repeat;
      background-position: right .5rem top 55%;
      background-size: 1.2rem auto;
        &:focus {
        outline: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>