<template>
  <button class="delete-btn" @click="deleteItem()">
    <div class="flex-wrapper">
      <span class="delete-btn__icon">
        <i class="material-icons">delete_forever</i>
      </span>
      <p class="delete-btn__title">Видалити</p>
    </div>
  </button>
</template>

<script>
export default {
  name:'v-delete-button',
  methods: {
    deleteItem() {
      this.$emit('deleteItem')
    } // TODO Implement function to remove item from list.
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
.delete-btn {
  appearance: none;
  border-style: none;
  background-color: transparent;
  padding: .6rem 1.8rem;
  cursor: pointer;
  &__icon i {
    color: $warn-txt;
  }
}
</style>