import Vue from 'vue'
import VueRouter from 'vue-router'
import vProductsArchive from '../components/v-products-archive'

Vue.use(VueRouter)

const routes = [
  {
    path: '/products',
    name: 'products',
    component: vProductsArchive,
    alias: '/home'
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../components/v-products-cart')
  },
  {
    path: '/ordering',
    name: 'order',
    component: () => import('../components/v-products-order')
  },
  {
    path: '/', redirect: { name: 'products'}
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../components/v-404-page')
  },
  {
    path: '*',
    redirect: { name: '404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/v-shop/',
  routes
})

export default router
