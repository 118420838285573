<template>
  <div class="app">
    <div class="app__loader-block">
      <v-loader v-if="IS_LOADER"></v-loader>
    </div>

    <div class="app__title flex-wrapper">
      <img :src="require('../src/assets/logo.png')" alt="logo">
      <h1>{{ title }}</h1>
      <div class="app__notice-wrapper">
        <v-notice-animated></v-notice-animated>
      </div>
    </div>

    <div v-if="isFrozen" :class="{'app__is-frozen-background': isFrozen}"></div>
    <div :class="{'app__is-frozen': isFrozen}">


      <div class="header flex-wrapper">
        <nav class="nav-menu header__nav-menu">
        
          <div class="nav-menu__button">
            <router-link
              class="nav-menu__link"
              :active-class="'a-active'"
              :to="{ name:'products' }"
            >
              PRODUCTS
            </router-link>
          </div>
        
          <div class="nav-menu__button">
            <div class="nav-menu__icon"><i class="material-icons">arrow_forward_ios</i></div>
            <div class="nav-menu__link">
              <router-link
                :active-class="'a-active'"
                :to="{ name:'cart' }" 
                :class="{ 'a-inactive': CART_PRODS_QT === 0 }"
              >CART 
                <span 
                  class="nav-menu__qt" 
                  v-if="CART_PRODS_QT > 0"
                >
                  / {{ CART_PRODS_QT }}
                </span>
              </router-link>
            </div>
          
          </div>
        
          <div class="nav-menu__button">
            <div class="nav-menu__icon"><i class="material-icons">arrow_forward_ios</i></div>
            <div class="nav-menu__link">
              <router-link
                :active-class="'a-active'"
                :to="{ name:'order' }" 
                :class="{ 'a-inactive': ORDER_PRODS === 0 }"
              >ORDER
                <span 
                  class="qt nav-menu__qt" 
                  v-if="ORDER_PRODS > 0"
                >
                  / {{ ORDER_PRODS }}
                </span>
              </router-link>
            </div>
          </div>
        
        </nav>
        <hr class="hr1 header__hr1">
      </div>

      <div class="main-wrapper">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'app',
  data () {
    return {
      title: 'Main page',
      isFrozen: false,
      isMobile: false
    }
  },
  methods: {
    ...mapActions ([
      'SET_MOBILE'
    ])
  },
  computed: {
    ...mapGetters([
      'CART_PRODS_QT', 'ORDER_PRODS', 'IS_FROZEN', 'IS_LOADER'
    ]),
    showQt () {
      return this.CART_PRODS_QT > 0
    }
  },
  watch: {
    IS_FROZEN (val) {
      this.isFrozen = val ? true : false
    },
    isMobile (val) {
      this.SET_MOBILE(val)
    }
  },
  beforeMount() {
    let screen = window.innerWidth
    if (screen < 768) {
      this.isMobile = true
    }
  },

  mounted () {
    this.$nextTick(window.addEventListener('resize', () => {
      window.innerWidth < 768 ? this.isMobile = true : this.isMobile = false
    }))
  },
  onBeforeUnmount () {
    window.removeEventListener('resize')
  }

}
</script>

<style lang="scss" scoped>

@import '../src/styles/variables';

.app {
  &__loader-block {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    @media (max-width: 980px) {
      top: 3rem
    }
    @media (max-width: 580px) {
      top: 10rem
    }
  }
  &__title {
    position: relative;
    justify-content: center;
    margin: 0 0 2rem 0;
    img {
      height: 3rem;
      @media (max-width: 580px) {
        height: 4rem;
      }
    }
    h1 {
      margin-left: 1rem;
      @media (max-width: 580px) {
        font-size: 2rem;
        line-height: 4rem;
      }
    }
    // &__notice-wrapper {
    // }
  }
  &__is-frozen-background {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(130, 127, 127, 0.463);
  }
  &__is-frozen * {
    // background-color: #743d3d;
    pointer-events: none;
    overflow-y: hidden;
  }

  .header {
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    @media (max-width: 580px) {
      justify-content: center;
    }

    & .nav-menu {
      flex: 0 1 fit-content;
      display: flex;
      //flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 580px) {
        flex-direction: column;
        justify-content: center;
        align-content: center;
      }
      &__button {        
        border-radius: $base-radius;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;
        margin-bottom: 1rem;
        a {
          min-width: 8rem;
        }
        @media (max-width: 580px) {
          align-items: center;
          flex-direction: column;
          margin-bottom: .2rem;
          a {
            width: 10rem;
            padding: 1rem;
            font-size: 1.2rem;
          }
        }
      }
      &__qt {
        color: yellow;
        font-weight: 500;
        @media (max-width: 580px) {
          font-size: 1.2rem;
        }
      }
      &__icon {
        margin-left: .5rem;
        padding: .3rem 0 0 0;
        @media (max-width: 580px) {
          z-index: -1;
          padding: 0;
          margin: 0;
          transform: rotate(90deg);
        }
      }
    }
    &__hr1 {
      flex: 1 1 60%;
      @media (max-width: 580px) {
        flex-basis: 100%;
      }
    }
  }
  .main-wrapper {
    margin: 0 1rem;
    @media (max-width: 580px) {
      margin: 0 .5rem;
    }
  }
}

</style>
