import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vMixin from './mixins/v-mixin'
import UI from '@/components/UI'
import customFilters from '@/filters'

import '@/styles/styles.scss'

Vue.config.productionTip = false
Vue.mixin(vMixin)

UI.forEach(UIcomp => Vue.component(UIcomp.name, UIcomp))
customFilters.forEach(customFilter => {
  const filterName = Object.keys(customFilter)[0]
  const filterMethod = Object.values(customFilter)[0]
  Vue.filter(filterName, filterMethod)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
