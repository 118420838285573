<template>
  <div class="v-product-archive-item prod-card">
    <!-- this block is temp tech info -->
    <div class="tech-info">
      {{ prodQt }} / 
      {{ inStockProducts }} /      
      {{ isInCart }} /
      SzID: {{ sizeID }} /
      {{ isInStock }}
    </div>
    <h2 class="prod-card__name">{{ product["prod-name"] }}</h2>
    <!--******************** IF inSTOCK ******************************-->
    <div class="in-stock-block prod-card__in-stock-block">
      <div class="in-stock-block__sign-block">
        <div class="in-stock-block__img">
          <div v-if="isInStock" class="in-stock-sign"></div>
          <div v-else class="out-of-stock-sign"></div>
        </div>
        <div class="in-stock-block__info-txt">
          <p v-if="isInStock">В наявності: <span class="qt">{{ inStockProducts }}</span> од.</p>
          <p>Артикль: {{ product.article }}</p>
          <p>ID: {{ ID + 1 }}</p>
        </div>
      </div>
    </div>
    <hr class="hr2">
    <div class="prod-card__img">
      <img :src="require('../assets/images/' + product.image)" alt="Product image">
    </div>
    <div class="prod-card__selectors selectors-wrapper">
      <div class="selectors">
      <!--************************** Size *****************************-->
        <div class="selectors__size">
          <v-size-selector
            :available="product.available"
            @changeSize="changeSize($event)">
          </v-size-selector>
        </div>
      <!--************************** QT *******************************-->
        <div class="selectors__qt" v-if="inStockProducts > 0">
          <v-qt-selector
            :isInStock="isInStock"
            :inStockQt="inStockProducts"
            :currQt="prodQt"
            @updateQt="updateQt($event)"
            @changeWarn="changeWarn($event)">
          </v-qt-selector>
        </div>
      </div>
    </div>
    <!--********************** PRICE & BUTTON **************************-->
    <div class="info-price-wrapper flex-wrapper">
      <div class="info-price info-price-wrapper__info-price">
        <p>Категорія: 
          <span class="info-price__gender">{{ product.category }}</span>
        </p>
        <p>Ціна: 
          <span class="info-price__price">{{ product.price | toAccFormat }} грн.</span>
        </p>
        <p v-show="prodSum > 0 && product.price !== prodSum">
          Cума: <span class="info-price__sum">{{ prodSum | toAccFormat }} грн.</span>
        </p>
      </div>
      <div class="info-price__btn">
        <v-prod-button
          :isInStock="isInStock"
          :isInCart="isInCart"
          :isWarn="isWarn"
          @clickBtn="clickBtn()"
          >
        </v-prod-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'v-product-archive-item',
  data () {
    return {
      prodQt: 0,
      currentSize: 'l',
      currentSizeID: 2,
      prodSum: 0,
      isWarn: false
    }
  },
  
  props: {
    product: { type: Object, default: () => {} },
    ID: { type: Number, default: 0 }
  },

  watch: {
    prodQt (qt) {
      this.prodSum = qt * this.product.price
    }
  },

  methods: {
    changeWarn (warn) {
      this.isWarn = warn
    },
    updateQt (qt) {
      if (qt[1] !== 0) {
        this.prodQt = qt[0]
      }
    },
    changeSize (sz) {
      this.currentSize = sz
    },
    clickBtn () {
      this.sendProdToCart()
    },
    sendProdToCart () {
      const cloneProd = structuredClone(this.product)
      cloneProd.prodID = this.ID
      cloneProd.prodSize = this.currentSize
      cloneProd.prodSizeID = this.sizeID
      cloneProd.prodQt = this.prodQt
      cloneProd.prodSum = this.prodSum
      this.$emit('sendToCart', cloneProd)
    }
  },

  computed: {
    ...mapGetters([
    'PRODUCTS', 'CART', 'IS_MOBILE'
    ]),    
    isInCart () {
      return this.CART.some(it => it.article === this.product.article && it.prodSize === this.currentSize)
    },
    inStockProducts () {
      return this.PRODUCTS[this.ID].available[this.sizeID][this.currentSize]
    },
    sizeID () {
      return this.product["available"].indexOf(this.product["available"].find((it) => {if (this.currentSize in it) return it}))
    },
    isInStock () {
      return (this.inStockProducts >= this.prodQt && this.inStockProducts !== 0) ? true : false
    }
  },
  beforeMount () {
    this.prodQt = 1
    this.$set(this.product, 'isInCart', false)
    this.$set(this.product, 'prodQt', 0)
    this.$set(this.product, 'prodSum', 0)
    this.$set(this.product, 'cartQt', 0)
    this.$set(this.product, 'cartSum', 0)
    this.$set(this.product, 'orderQt', 0)
    this.$set(this.product, 'orderSum', 0)
    this.prodSum = this.prodQt * this.product.price
  }
}
</script>

************************************ STYLES ******************************************
<style lang="scss" scoped>
@import '../styles/variables.scss';
.prod-card {
  height: 40.4rem;
  box-shadow: $main-shadow;
  padding: 1rem;
  &__name {
    margin-bottom: .5rem;
  }
  @media (max-width: 580px) {
    height: 32rem;
    width: 24rem;
    &__img {
      & img {
        height: 20rem;
        width: auto;
      }
    }
  }

  .in-stock-block {
    position: relative;

      &__sign-block {
        position: absolute;
        top: 20px;
        right: -1.5rem;
      }
      &__img {
        height: 4rem;
        & .in-stock-sign {
          background-image: url(../assets/images/in-stock.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 8rem;
          height: 4rem;
        }
        & .out-of-stock-sign {
          background-image: url(../assets/images/out-of-stock.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 8rem;
          height: 4rem;
        }
      }
      &__info-txt {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        width: 6rem;
        padding: .4rem;
        right: 2rem;
        p {
          font-size: .8rem;
          span {
            color: $salad-fnt;
          }
        }
      }
    }

  .selectors-wrapper {
    position: relative;
    .selectors {
      position: relative;
      height: 1.7rem;
      top: -2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__size {
        margin: 0 0 -3rem 0;
      }
      // &__qt {
      // }
    }
  }
  .info-price-wrapper {
    height: fit-content;
    // border: 1px solid #000;
    // &__info-price {
    // }
    .info-price {
      height: 5rem;
      margin: -1.5rem 0 .5rem 0;
      &__gender {
        font-size: 1rem;
        color: rgb(140, 140, 140);
      }
      &__price {
        font-size: 1.5rem;
        color: cadetblue;
      }
      &__sum {
        font-size: 1rem;
        color: cadetblue;
      }
      &__btn {
        align-self: start;
      }
    }
  }
}
</style>
