<template>
  <div class="v-products-archive">
    <h1 class="my-face">{{ title }}</h1>
    <main class="gallery">
      <prodsArchItem 
        v-for="(prod, idx) in PRODUCTS"
        :key="idx" 
        :product="prod"
        :ID = idx
        @sendToCart="sendToCart($event)">
      </prodsArchItem>
    </main>
  </div>
</template>

<script>
import prodsArchItem from '../components/v-products-archive-item'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { prodsArchItem },
  name: 'v-products-archive',
  data () {
    return {
      title: 'Gallery of products'
    }
  },
  computed: {
    ...mapGetters([
      'PRODUCTS'
    ])
  },
  watch: {
  },

  methods: {
    ...mapActions([
      'GET_PRODS_FM_SERVER', 'ADD_TO_CART', 'ADD_CURRENT_NOTICE'
    ]),

    sendToCart(prod) {
      this.ADD_CURRENT_NOTICE({'NOTICE_ARCHIVE_ADD_CART': this.makeID(), [prod["prod-name"]]: prod.prodQt})
      .then(() => this.ADD_TO_CART(prod))
    }
  },

  mounted() {
    this.GET_PRODS_FM_SERVER()
    .catch(err => console.log('Error >>> TO GET prods array from STORE', err))
  },
  // activated () {
  //   this.$forceUpdate()
  // }
}
</script>



<style lang="scss" scoped>
.v-products-archive {
  h1 {
    margin-bottom: 1.5rem;
    @media (max-width: 450px) {
      font-size: 1.5rem;
    }
  }
  .gallery {    
    display: grid;
    place-content: center;
    grid-template-columns: repeat(auto-fit, minmax(280px, 350px));
    grid-auto-flow: row;
    grid-auto-columns: minmax(300px, 350px);
    grid-gap: 1rem;
    @media (max-width: 1280px) {
      grid-template-columns: repeat(3, minmax(280px, 350px));
    }
    @media (max-width: 960px) {
      grid-template-columns: repeat(2, minmax(280px, 350px));
    }
    @media (max-width: 680px) {
      grid-template-columns: repeat(1, minmax(280px, 350px));
    }
  }
  
}
</style>
