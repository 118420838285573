<template>
  <div class="button">
    <div class="button__icon">
      <i class="material-icons" :class="{'inactive': isActive}">double_arrow</i>
    </div>
    <button 
      class="btn"
      type="button"
      :disabled="!isActive" 
      @click="askUser($event)"
    >
      <div class="flex-wrapper">
        <i class="material-icons">{{ icon }}</i>
        <slot></slot>
      </div>    
    </button>
  </div>
</template>

<script>
export default {
  name: 'v-order-button',
  props: {
    isActive: { type: Boolean, default: true },
    icon: { type: String, default: 'shopping_cart' }
  },
  methods: {
    askUser(event) {
      event.preventDefault()
      this.$emit('askUser')
    }
  }

}
</script>

<style lang="scss" scoped>
    .button {
      display: flex;
      align-items: center;
      &__icon {
        padding: .3rem 0 0 0;
      }
    }
</style>