import prodButton from '@/components/UI/v-prod-button.vue'
import qtSelector from '@/components/UI/v-qt-selector.vue'
import sizeSelector from '@/components/UI/v-size-selector.vue'
import notice from '@/components/UI/v-notice-block.vue'
import noticeAnimated from '@/components/UI/v-notice-animated.vue'
import deleteButton from '@/components/UI/v-delete-button.vue'
import orderButton from '@/components/UI/v-order-button.vue'
import loader from '@/components/UI/v-loader.vue'

export default [
  prodButton,
  qtSelector,
  sizeSelector,
  notice,
  noticeAnimated,
  deleteButton,
  orderButton,
  loader
]