import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"

import localDB from '../server/db.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    products: [],
    cart: [],
    order: [],
    purchase: [],

    headNoticeHistory: [],
    headNoticeCurrent: '',
    headNoticeUserResp: false,

    isWindowFrozen: false,
    isMobile: false,
    isLoaderActive: false
  },
  getters: {
    // MAIN ARRAY WITH PRODUCTS
    PRODUCTS: (state) => state.products,

    // CART GETTERS
    CART: (state) => state.cart,
    CART_PRODS_QT: (state) => state.cart.length,
    CART_SUM: (state) => {
      let sum = 0
      state.cart.forEach(it => {
        it.cartSum > 0 ? sum += it.cartSum : sum += it.prodSum
      })
      return sum > 0 ? sum : 0
    },
    CART_QT: (state) => {
      let qt = 0
      state.cart.forEach(it => {
        it.cartQt > 0 ? qt += Number(it.cartQt) : qt += Number(it.prodQt)
      })
      return qt > 0 ? qt : 0
    },

    // ORDER GETTERS
    ORDER: (state) => state.order,
    ORDER_PRODS: (state) => state.order.length,
    ORDER_SUM: (state) => {
      let sum = 0
      state.order.forEach(it => sum += it.orderSum)
      return sum
    },
    ORDER_QT: (state) => {
      let qt = 0
      state.order.forEach(it => qt += Number(it.orderQt))
      return qt
    },

    // HEAD NOTICE GETTERS
    CURRENT_NOTICE: (state) => {
      return state.headNoticeCurrent
    },
    CURRENT_NOTICE_RESP: (state) => {
      return state.headNoticeUserResp
    },
    CURRENT_NOTICE_NUM: (state) => {
      return +Object.keys(state.headNoticeUserResp)[0]
    },

    // READY TO BE SENT TO SERVER ARR
    PURCHASE: (state) => {
      //const purchaseArr = state.purchase.map((it) => it)
      //const purchaseArr = JSON.parse(JSON.stringify(state.purchase))
      const purchaseArr = structuredClone(state.purchase)
      return purchaseArr
    },

    // USED BY ANIMATED NOTICE
    IS_FROZEN: (state) => {
      return state.isWindowFrozen
    },

    IS_MOBILE: (state) => {
      return state.isMobile
    },
    IS_LOADER: (state) => {
      return state.isLoaderActive
    }
  },

  // MUTATIONS *********************************************************************
  mutations: {
    SET_PRODS_TO_STATE: (state, prods) => {
      state.products = prods
    },
    // TO CART MUTATIONS
    TO_CART: (state, newItem) => {
      function addToCart () {
        newItem.isInCart = true
        state.cart.push(newItem)
      }

      function checkCart () {
        const inCart = state.cart.filter((it, idx) => {
          it.cartID = idx
          return it.article === newItem.article
        })
        // console.log('Arr same article', inCart)
        if (inCart.length) {
          let foundTheSameSize = false
          inCart.map(it => {
            if (it.prodSizeID === newItem.prodSizeID) {
              foundTheSameSize = true
              it.prodQt = newItem.prodQt
              it.prodSum = newItem.prodSum
            }
          })
          if (!foundTheSameSize) addToCart()
          // console.log(theSame)
        } else addToCart()
      }
      state.cart.length ? checkCart() : addToCart()
    },

    UPDATE_CART_ITEM: (state, data) => {
      console.log('Update cart started...')
      state.cart.map(it => {
        if (it.cartID === data.cartID) {
          it.cartQt = data.cartQt
          it.cartSum = data.cartSum
        }
      })
    },

    DELETE_FROM_CART: (state, idx) => {
      state.cart[idx].isInCart = false
      state.cart.splice(idx, 1)
    },
    // TO ORDER MUTATIONS
    ORDER_CART: (state) => {
      // console.log(state.cart)
      state.products.map((it, idx) => {
        state.cart.forEach(itm => {
          // let cartIt = JSON.parse(JSON.stringify(itm))
          let cartIt = structuredClone(itm)
          if (idx === cartIt.prodID) {
            let currQt = it.available[cartIt.prodSizeID][cartIt.prodSize]
            let orderQt = cartIt.cartQt > 0 ? cartIt.cartQt : cartIt.prodQt
            let newQt = currQt - orderQt
            it.available[cartIt.prodSizeID][cartIt.prodSize] = newQt
            cartIt.available[cartIt.prodSizeID][cartIt.prodSize] = newQt
          }
        })        
      })
      if (!state.order.length) {
        console.log('Moving cart to order...')
        state.cart.map(itm => {
          itm.orderQt = itm.cartQt !== 0 ? itm.cartQt : itm.prodQt
          itm.orderSum = itm.cartSum !== 0 ? itm.cartSum : itm.prodSum
        })
        state.order.push(...state.cart)
        state.cart = []
      } else {
        console.log('Compare order & cart started...')
        state.cart.forEach((itm) => {
          // let cartIt = JSON.parse(JSON.stringify(itm))
          let cartIt = structuredClone(itm)
          let matchedID = ''
          let matchedProd = state.order.find((orderIt, orderIdx) => {
            if (orderIt.prodID === cartIt.prodID && orderIt.prodSizeID === cartIt.prodSizeID)   {
              console.log('cart prod. & order prod. matched')
              matchedID = orderIdx
              return orderIt
            }
          })
          // console.log(matchedProd)
          console.log(matchedID)
          if (matchedProd !== undefined) {
            let cartQt = cartIt.cartQt > 0 ? cartIt.cartQt : cartIt.prodQt
            state.order[matchedID].orderQt += cartQt
            let cartSum = cartIt.cartSum > 0 ? cartIt.cartSum : cartIt.prodSum
            state.order[matchedID].orderSum += cartSum
          } else  {
            cartIt.orderQt = cartIt.cartQt > 0 ? cartIt.cartQt : cartIt.prodQt
            cartIt.orderSum = cartIt.cartSum > 0 ? cartIt.cartSum : cartIt.prodSum
            state.order.push(cartIt)
          }
        })
        state.cart = []
      }
    },

    CANCEL_ORDER: (state) => {
      // let canceledOdr = JSON.parse(JSON.stringify(state.order))
      let canceledOdr = structuredClone(state.order)
      canceledOdr.forEach(it => {
        state.products[it.prodID].available[it.prodSizeID][it.prodSize] += it.orderQt
      })
      state.order = []
    },
    // PURCHASE, GET TO BE SENT TO SERVER
    PROCESS_ORDER: (state) => {
      // const currOrder = JSON.parse(JSON.stringify(state.order))
      const currOrder = structuredClone(state.order)
      currOrder.map(it => {
        return it.number = Object.values(state.headNoticeCurrent)[0]        
      })
      state.purchase.push(...currOrder)
      state.order = []
    },
    // NOTICE MUTATIONS
    SET_CURRENT_NOTICE: (state, notice) => {
      state.headNoticeCurrent = notice
    },
    SET_CURRENT_NOTICE_RESP: (state, val) => {
      state.headNoticeUserResp = val
    },

    SET_FEEZE_STATUS: (state, val) => {
      state.isWindowFrozen = val
    },

    SET_MOBILE_MODE: (state, val) => {
      state.isMobile = val
    },

    CLEAR_PURCHASE: (state) => {
      state.purchase = []
    },

    SET_LOADER: (state, val) => {
      state.isLoaderActive = val
    }
  },
// ACTIONS SERVER *************************************************************
  actions: {
    GET_PRODS_FM_SERVER({commit}) {
      return axios.get('http://localhost:3001/products')
      .then(resp => { 
        console.log(resp.data)
        if (resp.statusText === 'OK') {
          console.log('...taking json from server')
          commit('SET_PRODS_TO_STATE', resp.data)
          return resp.data
        }
      })
      .then(commit('SET_LOADER', false))
      .catch((err) => {
        console.log('***catched ERROR', err)
        console.log('...taking json from local folder')
        commit('SET_PRODS_TO_STATE', Object.values(localDB)[0])
        return localDB
      })
    },

    SEND_ORDER_TO_SERVER({ commit }, customer) {
      const orderNum = this.getters.CURRENT_NOTICE_NUM
      const order = this.getters.PURCHASE
      const toSend = {}
      toSend[orderNum] = order
      toSend.customer = customer
      return axios.post('http://localhost:3002/orders', toSend, {
          headers: { 'Content-Type': 'application/json' },
          maxRedirects: 0,
          // timeout: 5000
        })
      .then((resp) => {
        console.log('POST request resp.:', resp.data)
        if (resp.status === 201) {
          commit('CLEAR_PURCHASE')
        }
      })
      .then(commit('SET_LOADER', false))
      .catch((err) => {
        console.error('POST request error: ', err)
      })
    },

  // ACTIONS COMMON ****************************************************************
    ADD_TO_CART({commit}, item) {
      commit('TO_CART', item)
    },
    DELETE_FROM_CART({commit}, idx) {
      commit('DELETE_FROM_CART', idx)
    },
    UPDATE_CART({commit}, item) {
      commit('UPDATE_CART_ITEM', item)
    },
    ORDER({commit}) {
      commit('ORDER_CART')
    },
    CANCEL_ORDER({commit}) {
      commit('CANCEL_ORDER')
    },
    TO_PROCESS_ORDER({commit}) {
      commit('PROCESS_ORDER')
    },
    ADD_CURRENT_NOTICE({commit}, notice) {
      commit('SET_CURRENT_NOTICE', notice)
    },
    ADD_CURRENT_NOTICE_RESP({commit}, val) {
      commit('SET_CURRENT_NOTICE_RESP', val)
    },

    ADD_WINDOW_FREEZE_STATUS({commit}, val) {
      commit('SET_FEEZE_STATUS', val)
    },
    SET_MOBILE({commit}, val) {
      commit('SET_MOBILE_MODE', val)
    }
  }
})
